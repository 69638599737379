import { useContext, useEffect, useState } from 'react'
import '../../page/Booking/Booking.css'
import Fromgooleplace from '../shared/gooleplaceFromCom'
import { BookingCContext } from '../../store/context/bookingContext'
import FromgooleplaceTo from '../shared/googleplacetocom'
import FromgooleplaceVia from '../shared/googleplaceviaCom'
import { bookingTripschema, returnModalSchema } from '../../util/schema'
import { useFormik } from 'formik';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
// Modal.setAppElement('#yourAppElement');

function BookingDetails() {
    let { nextCurentslide,
        update_location_details,
        contextData,
        removeViaLocation,
        addviaddress,
        removeVialoaction

    } = useContext(BookingCContext)
    const { values, errors, touched, handleBlur, handleChange, handleSubmit
    } = useFormik({
        initialValues: {
            fromddress: contextData?.fromAddressData?.from_address,
            toadress: contextData?.toAddressData?.to_address,
            viaaddress: contextData?.viaAddress?.address,
            passenger: contextData?.location_pickup_details?.total_passenger,
            laguagge: contextData?.location_pickup_details?.total_luggage,
            pickupdate: contextData?.location_pickup_details?.pickup_date,
            addiAddress: contextData?.location_pickup_details?.pickup_flight_no,
            pickuptime: contextData?.location_pickup_details?.pickup_time,

            triptype: contextData?.location_pickup_details?.trip_type
        },
        validationSchema: bookingTripschema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async () => {
            nextCurentslide()
        }

    })

    const { values: retunrVlaue,
        errors: retunrerr, handleChange: retunrVlaueChange, handleSubmit: retunrVlauesub
    } = useFormik({
        initialValues: {
            return_flight_no: contextData?.location_pickup_details?.return_flight_no,
            return_time: contextData?.location_pickup_details?.return_time,
            return_date: contextData?.location_pickup_details?.return_date
        },
        validationSchema: returnModalSchema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async () => {
            isReturnModalOpen(!retuenmodalOpen)
        }

    })
    const [retuenmodalOpen, isReturnModalOpen] = useState()
    useEffect(() => {
        console.log('minute....', contextData?.location_pickup_details.pickup_minute)
    }, [contextData?.location_pickup_details?.pickup_hour])

    useEffect(() => {
        if (retunrVlaue?.return_date != null && values.triptype == 2) {
            return
        }
        if (values.triptype == 2) {
            isReturnModalOpen(true)
        } else {
            isReturnModalOpen(false)
        }
    }, [values.triptype])


    return (
        <>
            <ToastContainer />
            <Modal
                isOpen={retuenmodalOpen}
                onAfterOpen={() => { }}
                onRequestClose={() => { isReturnModalOpen(!retuenmodalOpen) }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='bookingForm'>
                    <form onSubmit={retunrVlauesub}>
                        <h6 className='text-center'>Return Details</h6>

                        <div
                            className='row'>
                            <div className='col-md-12'>
                                <div className='formgroup'>
                                    <label>Return Date *</label>
                                    <input
                                        type='date'
                                        id="return_date"
                                        value={retunrVlaue.return_date}
                                        onChange={(e) => update_location_details('retunrdate', e.target.value)}
                                        name="return_date"
                                        min={contextData?.location_pickup_details?.pickup_date}
                                    ></input>
                                    {retunrerr.return_date ? <small className="invalidationtext">{retunrerr?.return_date}</small> : null}

                                </div>
                            </div>
                        </div>
                        <div
                            className='row'>
                            <div className='col-md-12'>
                                <div className='formgroup'>
                                    <label>Return Time *</label>
                                    <input
                                        type='time'
                                        id="return_time"
                                        value={retunrVlaue.return_time}
                                        onChange={(e) => update_location_details('return_time', e.target.value)}
                                        name="return_time"
                                    ></input>
                                    {retunrerr.return_time ? <small className="invalidationtext">{retunrerr?.return_time}</small> : null}

                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='formgroup'>
                                    <label>Return Hour </label>
                                    <input
                                        type='time'
                                        id="return_hour"
                                        value={retunrVlaue.return_hour}
                                        onChange={(e) => update_location_details('return_hr', e.target.value

                                        )}
                                        name="return_hour"

                                    // disabled
                                    ></input>
                                    {retunrerr.return_hour ? <small className="invalidationtext">{retunrerr?.return_hour}</small> : null}


                                </div>
                            </div> */}
                            {/* <div className='col-md-6'>
                                <div className='formgroup'>
                                    <label> Minute </label>
                                    <input
                                        id="return_minute"
                                        value={retunrVlaue.return_minute}
                                        onChange={(e) => update_location_details('return_min', e.target.value)}
                                        name="return_minute"
                                        disabled
                                    ></input>
                                    {retunrerr.return_minute ? <small className="invalidationtext">{retunrerr?.return_minute}</small> : null}


                                </div>
                            </div> */}
                        </div>
                        <div
                            className='row'>
                            <div className='col-md-12'>
                                <div className='formgroup'>
                                    <label>Flight Number, Train Station, Sea Port, Ferry Name</label>
                                    <input
                                        id="return_flight_no"
                                        value={retunrVlaue?.return_flight_no}
                                        onChange={(e) => update_location_details('return_details', e.target.value)}
                                        name="return_flight_no"
                                        placeholder='Flight Number, Train Station, Sea Port, Ferry Name'
                                    ></input>
                                    {/* {retunrerr.return_flight_no ? <small className="invalidationtext">{retunrerr?.return_flight_no}</small> : null} */}

                                </div>
                            </div>
                        </div>
                        <div
                            className='row retunrn-submit'>
                            {/* <div className='retunrn-submit'> */}

                            <button type='submit' className='nextBtn'>Save</button>
                            <button type='button' className='closebtn' onClick={() => {


                                if (retunrVlaue?.return_date != null && retunrVlaue?.return_flight_no !== null
                                    && retunrVlaue?.return_time !== null


                                ) {
                                    isReturnModalOpen(!retuenmodalOpen)
                                    return
                                }
                                update_location_details('return_close')
                                isReturnModalOpen(!retuenmodalOpen)

                            }
                            }>Close</button>
                            {/* </div> */}
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='bookingForm'>
                <form onSubmit={handleSubmit}>
                    <h3>Booking Trip</h3>
                    <div
                        className='row'>
                        <div className='col-md-6'>
                            <div className='formgroup'>
                                <label>From *</label>
                                <Fromgooleplace
                                    error={errors}
                                />


                            </div>
                            <div className='formgroup'>
                                <label>Via</label>
                                <div className='viaadrs'>
                                    <FromgooleplaceVia />
                                    <div className='btngrp'>
                                        <button type='button'
                                            disabled={contextData?.viaAddress?.address == '' ? true : false}
                                            onClick={addviaddress}
                                            className='addbtn'><i className="fa fa-plus" aria-hidden="true"></i></button>
                                        <button type='button' className='rmvbtn' onClick={removeViaLocation}><i className="fa fa-times" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div>
                                    {
                                        contextData?.viaaddressArray?.map((via, index) => {
                                            return <p>{via?.address} <i className='fa fa-times'
                                                onClick={() => removeVialoaction(index)}

                                                style={{
                                                    color: 'red'
                                                }}
                                            ></i></p>
                                        })
                                    }

                                </div>

                            </div>
                            <div className='formgroup'>
                                <label>To *</label>
                                <FromgooleplaceTo
                                    error={errors}
                                />
                            </div>
                            {
                                contextData?.location_pickup_details?.trip_type != 1 ?

                                    (contextData?.location_pickup_details?.return_date && retunrVlaue?.return_flight_no !== null
                                        && retunrVlaue?.return_hour !== "00:00" && retunrVlaue?.return_minute !== '00:00'

                                    ) ?
                                        <div className='return-details mt-3'>
                                            <p>Return Date : {contextData?.location_pickup_details?.return_date} <i class="fa fa-pencil-square-o"
                                                onClick={() => isReturnModalOpen(true)}
                                                style={{
                                                    color: '#CC8B16',
                                                    marginLeft: 20,
                                                    cursor: 'pointer'
                                                }}
                                                aria-hidden="true"></i></p>
                                            <p>Time :{retunrVlaue?.return_time}</p>
                                            <p>Flight Number, Train Station, Sea Port, Ferry Name:</p>
                                            {contextData?.location_pickup_details?.return_flight_no}
                                        </div>
                                        :

                                        null
                                    :
                                    null
                            }

                            <div className='customRdo'>
                                <p>
                                    <input
                                        type="radio"
                                        checked={values.triptype == 1 ? true : false}
                                        id="test1"
                                        onChange={(e) => update_location_details('trip_type', 1)}
                                        name="radio-group" />
                                    <label for="test1">One way</label>
                                </p>
                                <p>
                                    <input
                                        checked={values.triptype == 2 ? true : false}
                                        onChange={

                                            (e) => {
                                                if (contextData?.location_pickup_details?.pickup_date === null) {
                                                    toast.error('Please Select Pick-up Date')
                                                    return
                                                }
                                                update_location_details('trip_type', 2)
                                            }}

                                        type="radio" id="test2" name="radio-group" />
                                    <label for="test2">Return</label>
                                </p>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='formgroup'>
                                        <label>Passengers *</label>
                                        <select
                                            name='passenger'
                                            onChange={(e) => update_location_details('passenser', e.target.value)}
                                            value={values.passenger}>
                                            <option value={1} >1</option>
                                            <option value={2} >2</option>
                                            <option value={3} >3</option>
                                            <option value={4} >4</option>
                                            <option value={5} >5</option>
                                            <option value={6} >6</option>
                                            <option value={7} >7</option>
                                            <option value={8} >8</option>
                                            <option value={9} >9</option>
                                            <option value={10} >10</option>
                                        </select>
                                        {errors.passenger ? <small className="invalidationtext">{errors?.passenger}</small> : null}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='formgroup'>
                                        <label>Luggage *</label>
                                        <select
                                            name='laguagge'
                                            onChange={(e) => update_location_details('luggage', e.target.value)}
                                            value={values.laguagge}
                                        >
                                            <option value={1} >1</option>
                                            <option value={2} >2</option>
                                            <option value={3} >3</option>
                                            <option value={4} >4</option>
                                            <option value={5} >5</option>
                                            <option value={6} >6</option>
                                            <option value={7} >7</option>
                                            <option value={8} >8</option>
                                            <option value={9} >9</option>
                                            <option value={10} >10</option>
                                        </select>
                                        {errors.laguagge ? <small className="invalidationtext">{errors?.laguagge}</small> : null}

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='formgroup'>
                                        <label>Pick Up Date *</label>
                                        <input
                                            name='pickupdate'
                                            value={values.pickupdate}
                                            onChange={(e) => update_location_details('pickup_data', e.target.value)}
                                            type='date'
                                        // min={contextData?.location_pickup_details?.return_date}
                                        />

                                        {errors.pickupdate ? <small className="invalidationtext">{errors?.pickupdate}</small> : null}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='formgroup '>
                                        <label>Pick Up Time *</label>
                                        <input
                                            name='pickuptime'
                                            value={values.pickuptime}
                                            onChange={(e) => update_location_details('pickup_time', e.target.value)}
                                            type='time'
                                        // min={contextData?.location_pickup_details?.return_date}
                                        />

                                        {errors.pickuptime ? <small className="invalidationtext">{errors?.pickuptime}</small> : null}
                                        {/* <div className=''>
                                            <label>Hour *</label>
                                            <input
                                                type='time'
                                                name='pickuptime'
                                                onChange={(e) => update_location_details('pickup_hour', e.target.value)}
                                                value={values.pickuptime}
                                                placeholder={values.pickuptime}

                                            />
                                            {errors.pickuptime ? <small className="invalidationtext">{errors?.pickuptime}</small> : null}

                                        </div>
                                        <div className=''>
                                            <label>Min *</label>
                                            <input
                                                type='time'
                                                name='pickuptimemin'
                                                disabled
                                                value={values.pickuptimemin}
                                                placeholder='0:00' />
                                        </div> */}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='formgroup'>
                                        <label>Flight Number, Train Station, Sea Port, Ferry Name</label>
                                        <input
                                            name='addiAddress'
                                            onChange={(e) => update_location_details('pickup_flight_no', e.target.value)}
                                            value={values.addiAddress}
                                            type='text' 
                                            placeholder='Flight Number, Train Station, Sea Port, Ferry Name'
                                            />
                                        {/* {errors.addiAddress ? <small className="invalidationtext">{errors?.addiAddress}</small> : null} */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='frmBtn'>

                        <button type='submit'

                            className='nextBtn'>Next</button>
                    </div>
                </form>

            </div>
        </>
    )
}
export default BookingDetails