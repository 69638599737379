import Pagebanner from "../../component/PageBanner/Pagebanner";
import maps from "../../assets/maps.png";
import Skeleton from '@mui/material/Skeleton';
import like from "../../assets/like.png";
import gallery1 from "../../assets/gallery1.png";
import gallery2 from "../../assets/gallery2.png";
import gallery3 from "../../assets/gallery3.png";
import { Link } from 'react-router-dom';
import './Gallery.css';
import { useQuery } from "@tanstack/react-query";
import { fetchGallery, fetchHomeFootercms } from "../../feature/cms";
import { useContext, useEffect, useState } from "react";
import { BookingCContext } from "../../store/context/bookingContext";
const Gallery = () => {
   let { contextData } = useContext(BookingCContext)
   const [selectedImage, setSelectedImage] = useState(null);

   let { isLoading: footerloading, data: footerdata } = useQuery({ queryKey: ['fottercms'], queryFn: fetchHomeFootercms })
   let { isLoading: galletloading, data: galleryData } = useQuery({ queryKey: ['gallery'], queryFn: fetchGallery })

   const openFullView = (image) => {
      setSelectedImage(image);  // Set the selected image when clicked
   };

   const closeFullView = () => {
      setSelectedImage(null);  // Close the full view by resetting the selected image
   };
   useEffect(() => {
      window.scrollTo({
         top: 0,    // Scroll to the top of the page
         behavior: 'smooth'  // Smooth scroll
      });
   }, [])
   return (
      <div className="galleryPage">
         {selectedImage && (
            <div className="full-image-view" onClick={closeFullView}>
               <span className="close-btn">&times;</span>
               <img src={selectedImage} alt="Full View" className="full-img" />
            </div>
         )}
         <Pagebanner />
         <div className="gallerbox">
            <div className="container">
               <div className="row">
                  {
                     galletloading ? <>
                        <div className="col-md-4"><Skeleton variant='rectangular'
                           animation='wave' className="galleryboxItem"
                           height={325}
                        />

                        </div>
                        <div className="col-md-4"><Skeleton variant='rectangular'
                           animation='wave' className="galleryboxItem"
                           height={325}
                        />

                        </div>
                        <div className="col-md-4"><Skeleton variant='rectangular'
                           animation='wave' className="galleryboxItem"
                           height={325}
                        />

                        </div>
                     </> :
                        galleryData?.map((image) => {
                           return (

                              <div className="col-md-4"
                                 onClick={() => openFullView(image?.image_path)}
                              >

                                 <div className="galleryboxItem">
                                    <img src={image?.image_path} />
                                 </div>

                              </div>
                           )

                        })
                  }


               </div>
            </div>
         </div>
         <div className="whyChooseUs">
            <div className="container">


               <div className="row">
                  <div className="col-md-12">
                     <div className="callbook" style={{ backgroundImage: `url(${footerdata?.image_3_path})` }}>
                        <h2>{footerdata?.title_3}</h2>
                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description_3 }}></p>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="chooseItem">
                        <div className="chooseItemtop">
                           <img src={footerdata?.image_1_path} /> <h3>{footerdata?.title}</h3>
                        </div>
                        {
                           footerloading ?
                              <>
                                 <Skeleton animation='wave' />
                                 <Skeleton animation='wave' />
                                 <Skeleton animation='wave' />
                                 <Skeleton animation='wave' />
                              </>
                              :

                              <p dangerouslySetInnerHTML={{ __html: footerdata?.description }}></p>
                        }
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="chooseItem">
                        <div className="chooseItemtop">
                           <img src={footerdata?.image_2_path} /> <h3>{footerdata?.title_2}</h3>
                        </div>
                        {
                           footerloading ?
                              <>
                                 <Skeleton animation='wave' />
                                 <Skeleton animation='wave' />
                                 <Skeleton animation='wave' />
                                 <Skeleton animation='wave' />
                              </>
                              :

                              <p dangerouslySetInnerHTML={{ __html: footerdata?.description_2 }}></p>
                        }
                     </div>
                  </div>
                  <div className="col-md-12">
                     <div className="contInfo">
                        <h2>{footerdata?.title_3}</h2>
                        <div className="btnGrp">
                           <a className="bookBtn btn1"
                              href={`tel:+${contextData?.mobilenumber}`}
                              to="">Call to book <span><i className="fa fa-phone" aria-hidden="true"></i></span></a>

                           <Link className="bookBtn" to="/booking">Book Now <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Gallery;