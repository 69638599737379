import './PageBanner.css';
import pagebanner from "../../assets/banner.png";
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchBanner } from '../../feature/cms';
const Pagebanner = ({ imagepath }) => {
    const [pageName, setPageName] = useState();
    const [pageTitel, setpageTitel] = useState();

    
    let { isLoading: loading, data, error } = useQuery(['banner', pageName], () => fetchBanner(pageName), {
        enabled: !!pageName, // Only run the query if slug is defined
    })

    useEffect(() => {
        setPageName(window.location.pathname.slice(1))
        switch (pageName) {
            case "about-us":
                setpageTitel("About Us")
                break;
            case "service":
                setpageTitel("Our Service")
                break;
            case "gallery":
                setpageTitel("Our Gallery")
                break;
            case "contact-us":
                setpageTitel("Contact Us")
                break;
            case "terms-and-conditions":
                setpageTitel("Terms and Condition")
                break;
            case "booking":
                setpageTitel("Booking")
                break;
            default:
                break;
        }
    }, [pageName]);

    return (
        <>
            {
                loading ? <Skeleton className='pageBanner' animation='wave' />

                    :
                    <div className="pageBanner">
                        <h1>{pageTitel}</h1>
                        <img src={pageTitel == 'Our Service' ? imagepath : data?.image_path} />
                    </div>
            }
        </>

    )
}
export default Pagebanner;