
import './App.css';
import { Outlet } from 'react-router-dom';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import 'react-toastify/dist/ReactToastify.css';
// import { Provider } from 'react-redux';
// import { store } from './store/store';
import Modal from 'react-modal';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import Layout from './component/Layout/Layout';
import { useEffect } from 'react';
import ContextWraper from './store/context/bookingContext';
import Spinner from './component/shared/loading';
function App() {

  const queryClient = new QueryClient()

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const render = (status) => {
    if (status === Status.LOADING) return <Modal
      isOpen={Status.LOADING}
      onAfterOpen={() => { }}
      // onRequestClose={() => { isReturnModalOpen(!retuenmodalOpen) }}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Spinner />
    </Modal>;
  };


  return (
    // <Provider store={store}>

    <QueryClientProvider client={queryClient}>
      <PayPalScriptProvider options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        components: "buttons", currency: "USD"
      }}>
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY} render={render}>
          <ContextWraper>
            <div className="App">

              <Layout>
                <Outlet />
              </Layout>
            </div>
          </ContextWraper>

        </Wrapper>
      </PayPalScriptProvider>
    </QueryClientProvider>


  );
}

export default App;
