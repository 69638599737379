import axios from 'axios';
import baseurl from '../util/axiosBase';

const fetchhomeCmsData = async () => {
    let _data = await axios.post(`${baseurl}/home`);
    return _data?.data?.data
}

const fetchwhycchooseUs = async () => {
    let _data = await axios.post(`${baseurl}/why-choose-us`)
    return _data?.data?.data
}

const fetchourService = async () => {
    let _data = await axios.post(`${baseurl}/services`)
    return _data?.data?.data
}
const fetchHomeFootercms = async () => {
    let _data = await axios.post(`${baseurl}/service`)
    return _data?.data?.data
}

const fetchHomeSliders = async () => {
    let _data = await axios.post(`${baseurl}/sliders`)
    return _data?.data?.data
}

const fetchaboutUs = async () => {
    let _resdata = await axios.post(`${baseurl}/about-us`)
    return _resdata?.data?.data
}

const fetchBanner = async (slug) => {
    let data = await axios.post(`${baseurl}/banner`, { slug: slug }, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data?.data?.data
}

const fetchGallery = async () => {
    let data = await axios.post(`${baseurl}/gallery`)
    return data?.data?.data
}

const sendContactdetails = async (data) => {
    let res_data = await axios.post(`${baseurl}/contact-form`, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res_data?.data
}

const fetchserviceDetailsById = async (serviceid) =>{
    let _data = await axios.post(`${baseurl}/services-details`,{id:serviceid},{
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return _data?.data?.data
}

const fetchtermsandcondition = async () =>{
    let data = await axios.post(`${baseurl}/terms-and-conditions`)
    return data?.data?.data
}
 const getsettingDetails = async () =>{
    let data = await axios.post(`${baseurl}/site-settings`)
    return data?.data?.data
 }
export {
    fetchhomeCmsData,
    fetchwhycchooseUs,
    fetchourService,
    fetchHomeFootercms,
    fetchHomeSliders,
    fetchBanner,
    fetchaboutUs,
    fetchGallery,
    sendContactdetails,
    fetchserviceDetailsById,
    fetchtermsandcondition,
    getsettingDetails
}