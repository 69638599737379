import { useContext } from "react";
import { BookingCContext } from "../../store/context/bookingContext";

function BookingTrackingStage() {
  let { contextData } = useContext(BookingCContext)
  return (
    <ul className='lbl3'>
      <li className={contextData?.usercurrentSlide == 1 || contextData?.usercurrentSlide == 2 || contextData?.usercurrentSlide == 3
       || contextData?.usercurrentSlide == 4
        ? 'sucess' : 'active'}>1</li>
      <li className={contextData?.usercurrentSlide == 2 || contextData?.usercurrentSlide == 3 || contextData?.usercurrentSlide == 4 ? 'sucess' : 'active'}>2</li>
      <li className={contextData?.usercurrentSlide == 3 || contextData?.usercurrentSlide == 4 ? 'sucess' : 'active'}>3</li>
      <li className={contextData?.usercurrentSlide == 4 ? 'sucess' : 'active'}>4</li>
    </ul>
  )
}

export default BookingTrackingStage;