import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './component/Layout/Layout';
import Home from './page/Home/Home';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Routes
} from "react-router-dom";
import AboutUs from './page/AboutUs/AboutUs';
import Service from './page/Service/Service';
import Gallery from './page/Gallery/Gallery';
import ContactUs from './page/ContactUs/ContactUs';
import TearmCondition from './page/TearmCondition/TearmCondition';
import Booking from './page/Booking/Booking';
const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />} >
        <Route path='' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/service' element={<Service />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/terms-and-conditions' element={<TearmCondition />} />
        <Route path='/booking' element={<Booking />} />

      </Route>

    </>
  )
)


root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
