
import './Home.css';
import Skeleton from '@mui/material/Skeleton';
import { Link, useNavigate } from "react-router-dom";
import item1 from "../../assets/item1.png";
import item2 from "../../assets/item2.png";
import item3 from "../../assets/item3.png";
import accept from "../../assets/accept.png";
import 'react-accessible-accordion/dist/fancy-example.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'swiper/css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    fetchBanner, fetchhomeCmsData,
    fetchHomeFootercms, fetchHomeSliders,
    fetchourService, fetchwhycchooseUs,
    getsettingDetails
} from '../../feature/cms';
import Fromgooleplace from '../../component/shared/gooleplaceFromCom';
import FromgooleplaceVia from '../../component/shared/googleplaceviaCom';
import FromgooleplaceTo from '../../component/shared/googleplacetocom';
import { useContext } from 'react';
import { BookingCContext } from '../../store/context/bookingContext';
import { useFormik } from 'formik';
import { getquestshema } from '../../util/schema';
import Modal from 'react-modal';
import { sendquatationrequest } from '../../feature/booking';

const Home = () => {
    const [getInnerwidth, setinnerWidth] = useState();
    const [slideItem, setslideItem] = useState(4);
    const [getquestmpdal, setgetquetmodal] = useState(false)
    const autoplays = true;
    const navigate = useNavigate()
    const [ismmoreshow, setismoreshoe] = useState(false)
    let { data: settingdata } = useQuery({ queryKey: ['setting'], queryFn: getsettingDetails })
    let { isLoading: loading, data, error } = useQuery({ queryKey: ['home'], queryFn: fetchhomeCmsData })
    let { isLoading: whychooseusLoading, data: chooseusData } = useQuery({ queryKey: ['chooseus'], queryFn: fetchwhycchooseUs })
    let { isLoading: serviceloading, data: serviceData } = useQuery({ queryKey: ['service'], queryFn: fetchourService })
    let { isLoading: footerloading, data: footerdata } = useQuery({ queryKey: ['fottercms'], queryFn: fetchHomeFootercms })
    let { isLoading: sliderloading, data: sliderData } = useQuery({ queryKey: ['slider'], queryFn: fetchHomeSliders })
    let { isLoading: homebannerloading, data: homebanner } = useQuery(['banner', 'home'], () => fetchBanner('home'), {
        enabled: !!'home', // Only run the query if slug is defined
    })

    let { update_location_details, contextData } = useContext(BookingCContext)
    useEffect(() => {
        update_location_details('googlemapkey', settingdata?.google_api_key)
        update_location_details('phonenumber', settingdata?.phone)
    }, [])
    function viewDetailsService(id) {
        console.log(data, 'servicesssss')
        update_location_details('viewserviceDetails', id)

        navigate('/service')
    }


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm
    } = useFormik({
        initialValues: {
            from_address: contextData?.fromAddressData?.from_address,
            to_address: contextData?.toAddressData?.to_address,
            via_address: contextData?.viaAddinputselected?.formatted_address || '',
            pickup_date: '',
            pickup_time: '',
            luggage: 1,
            passenger: 1,
            name: '',
            email: '',
            phone: '',
            tip_type: null
        },
        validationSchema: getquestshema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,

        onSubmit: async (values) => {

            mutation.mutate(values);
            // nextCurentslide()
            // Do the slide change only after form submission is successful
            // onValidate();
        }

    })
    const mutation = useMutation(sendquatationrequest, {
        onSuccess: (data) => {
            if (data.status == true) {
                setgetquetmodal(true)
                resetForm()

                setTimeout(() => {
                    setgetquetmodal(false)
                }, 5000);
            }

        },
        onError: (error) => {
            console.error('Error submitting form:', error);
        }
    });
    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,    // Scroll to the top of the page
    //         behavior: 'smooth'  // Smooth scroll
    //     });
    // }, [])
    useEffect(() => {
        setinnerWidth(window.innerWidth);
        if (getInnerwidth < 992) {
            setslideItem(3)
        }
        if (getInnerwidth < 768) {
            setslideItem(2)
        }
        if (getInnerwidth < 480) {
            setslideItem(1)
        }
    }, [getInnerwidth]);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    return (
        <div className="Home">
            <Modal
                isOpen={getquestmpdal}
                onAfterOpen={() => { }}
                onRequestClose={() => { setgetquetmodal(false) }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='getquet-modal'>
                    <h6 className='text-center'>Thank You <br /> For Your Quotation</h6>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#35AD0A" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </div>

                    <p className='text-center'>We will get back to you soon.</p>
                </div>
            </Modal>
            <div className='pageSLider'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    loop={true}
                    cssMode={true}
                    modules={[Autoplay]}
                >
                    {
                        sliderloading ? <Skeleton
                            variant="rectangular"
                            className='slider' height={500} />
                            :
                            sliderData?.map((banner) => {
                                return <SwiperSlide>
                                    <div className="slider">
                                        <img src={banner?.image_path} />
                                        <h1 dangerouslySetInnerHTML={{ __html: banner?.title }}>

                                        </h1>
                                        <div className="btnGrp">
                                            <a
                                                href={`tel:+${contextData?.mobilenumber}`}
                                                className="bookBtn btn1">Call to book <span><i className="fa fa-phone" aria-hidden="true"></i></span></a>

                                            <Link className="bookBtn" to='/booking'>Book Now <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            })
                    }

                </Swiper>
            </div>

            <div className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {
                                homebannerloading ? <Skeleton variant='rectangular' height={400} className='aboutPic' />
                                    :
                                    <div className="aboutPic">
                                        <img src={homebanner?.image_path} />
                                    </div>
                            }

                        </div>
                        <div className="col-md-6">
                            <div className="aboutTxt">
                                <h2 dangerouslySetInnerHTML={{ __html: data?.title_5 }}></h2>
                                {
                                    loading ?
                                        <>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                        </>

                                        :
                                        <div className={ismmoreshow ? '' : 'aboutTxtt'}>
                                            <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>

                                        </div>
                                }

                                <Link className="bookBtn"
                                    onClick={() => setismoreshoe(!ismmoreshow)}
                                    to="">{ismmoreshow ? 'Read Less' : 'Read More'} <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ourService">
                <div className="container">
                    <div className="heading">
                        <span>our services</span>
                        <h3>Explore our wide range of services</h3>
                    </div>
                    <div className="serviceSlider">
                        <Swiper
                            spaceBetween={24}
                            cssMode={true}
                            slidesPerView={slideItem}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            modules={[Autoplay]}
                        // loop={true}
                        >

                            {
                                serviceData?.map((item, index) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="servicesItem">
                                                <div className="servicesItemImg">
                                                    <img src={item?.image_path} />
                                                </div>
                                                <h4>{item?.name}</h4>
                                                {
                                                    serviceloading ?
                                                        <>
                                                            <Skeleton animation="wave" />
                                                            <Skeleton animation="wave" />
                                                            <Skeleton animation="wave" />
                                                            <Skeleton animation="wave" />
                                                        </>
                                                        :
                                                        <p
                                                            style={{
                                                                height: 50
                                                            }}
                                                            dangerouslySetInnerHTML={{ __html: item?.description }} />
                                                }


                                                {/* From the Great Ocean Road Tour to
                                                Phillip Island or the Daylesford tour,
                                                Melbourne has got everything */}

                                                <Link onClick={() => viewDetailsService(index)} className="arwBtn">
                                                    <i

                                                        className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    </div>
                </div>
            </div>
            <div className='gataQueat'>
                <div class="heading"><h3>Get A Quote</h3></div>
                <div className="container">
                    <form className="sliderForm" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <label>From *</label>
                                    <Fromgooleplace
                                    />
                                    {errors.from_address ? <small className="invalidationtext">{errors?.from_address}</small> : null}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <label>Via</label>
                                    <FromgooleplaceVia />

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="formGroup">
                                            <label>Passengers</label>
                                            <input
                                                name='passenger'
                                                id='passenger'
                                                value={values.passenger}
                                                onChange={handleChange}
                                                type="number" placeholder="Enter Number" />
                                            {errors.passenger ? <small className="invalidationtext">{errors?.passenger}</small> : null}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="formGroup">
                                            <label>Luggage</label>
                                            <input
                                                name='luggage'
                                                id='luggage'
                                                value={values.luggage}
                                                onChange={handleChange}
                                                type="number" placeholder="Enter Number" />
                                            {errors.luggage ? <small className="invalidationtext">{errors?.luggage}</small> : null}

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <label>To *</label>
                                    <FromgooleplaceTo />
                                    {errors.to_address ? <small className="invalidationtext">{errors?.to_address}</small> : null}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="formGroup">
                                            <label>Date *</label>
                                            <input
                                                name='pickup_date'
                                                id='pickup_date'
                                                value={values.pickup_date}
                                                onChange={handleChange}
                                                type="date" placeholder="Enter Location" />
                                            {errors.pickup_date ? <small className="invalidationtext">{errors?.pickup_date}</small> : null}

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="formGroup">
                                            <label>Time *</label>
                                            <input
                                                name='pickup_time'
                                                id='pickup_time'
                                                value={values.pickup_time}
                                                onChange={handleChange}
                                                type="time" />
                                            {errors.pickup_time ? <small className="invalidationtext">{errors?.pickup_time}</small> : null}

                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="formGroup">
                                            <label>Name *</label>
                                            <input
                                                name='name'
                                                id='name'
                                                value={values.name}
                                                onChange={handleChange}
                                                type="text" placeholder="Enter Full Name" />
                                            {errors.name ? <small className="invalidationtext">{errors?.name}</small> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="formGroup">
                                            <label>Phone Number *</label>
                                            <input
                                                name='phone'
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}

                                                id='phone'
                                                value={values.phone}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 10) {
                                                        setFieldValue('phone', e.target.value)
                                                    }
                                                }
                                                }
                                                type="Number" placeholder="Enter Phone Number" />
                                            {errors.phone ? <small className="invalidationtext">{errors?.phone}</small> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="formGroup">
                                            <label>Email *</label>
                                            <input
                                                name='email'
                                                id='email'
                                                value={values.email}
                                                onChange={handleChange}
                                                type="text" placeholder="Enter Email" />
                                            {errors.email ? <small className="invalidationtext">{errors?.email}</small> : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="formGroup">
                                    <div className="flxcheck">
                                        <p><input
                                            checked={values.tip_type == 1 ? true : false}
                                            name='tip_type'
                                            type="checkbox"
                                            id="single"
                                            value='single'
                                            onChange={() => { setFieldValue('tip_type', 1) }}
                                        />
                                            <label for="single">Single Trip</label></p>
                                        <p><input
                                            checked={values.tip_type == 2 ? true : false}
                                            name='tip_type'
                                            type="checkbox"
                                            id="round"
                                            value='round'
                                            onChange={() => { setFieldValue('tip_type', 2) }}
                                        />
                                            <label for="round">Round Trip</label></p>
                                    </div>
                                    {errors.tip_type ? <small className="invalidationtext">{errors?.tip_type}</small> : null}

                                    {/* <br /> */}

                                    <button
                                        disabled={mutation.isLoading ? true : false}
                                        type="submit"
                                        // onClick={() => navigate('/booking')}
                                        className="formBtn">Get A Quote
                                        {mutation.isLoading ? <i class="fa fa-spinner" aria-hidden="true"></i> : null
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
            <div className="acrodiantServices">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="acrdntServices">
                                <h2>{data?.title_6}</h2>
                                <p>{data?.sub_title}</p>
                                <div className="acservice">
                                    <Accordion>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <img src={item1} /> {data?.title_2}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    {data?.description_2}
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <img src={item2} /> {data?.title_3}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    {data?.description_3}
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <img src={item3} /> {data?.title_4}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p>
                                                    {data?.description_4}
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="serviceImg">
                                <img src={data?.image_1_path} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whyChooseUs">
                <div className="container">
                    <div class="heading"><span>Why Choose Us</span><h3>
                        {chooseusData?.title}

                    </h3></div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description}</p>

                                }

                            </div>
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description_2}</p>
                                }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="whyChooseImg">
                                <img src={chooseusData?.image_1_path} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description_3}</p>
                                }
                            </div>
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description_4}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="chooseItem">
                                <div className="chooseItemtop">
                                    <img src={footerdata?.image_1_path} /> <h3>{footerdata?.title}</h3>
                                </div>
                                {
                                    footerloading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :

                                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description }}></p>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="chooseItem">
                                <div className="chooseItemtop">
                                    <img src={footerdata?.image_2_path} /> <h3>{footerdata?.title_2}</h3>
                                </div>
                                {
                                    footerloading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :

                                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description_2 }}></p>
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contInfo">
                                <h2>{footerdata?.title_3}</h2>
                                <div className="btnGrp">
                                    <a href="tel:+98494949499" className="bookBtn btn1" to="">Call to book <span><i className="fa fa-phone" aria-hidden="true"></i></span></a>

                                    <Link className="bookBtn" to="/booking">Book Now <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}
export default Home;