
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import {
  APIProvider,
  useMapsLibrary,
} from "@vis.gl/react-google-maps"
import { BookingCContext } from "../../store/context/bookingContext";
import './style.css'
const PlaceAutocomplete = ({ onPlaceSelect, error, bookifrom, contextData, updateFormAddinput }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState(contextData?.fromAddinputselected?.formatted_address || '');
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");
  const [path, setpath] = useState('')
  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    // setPlaceAutocomplete()
  }, [places]);


  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update the input field value on change
  };
  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      let placeData = placeAutocomplete.getPlace()
      let modyfydata = {
        from_address: placeData?.formatted_address,
        from_latitude: placeData?.geometry?.location.lat(),
        from_longitude: placeData?.geometry?.location.lng()
      }

      let _selectedInputVisible = {
        formatted_address: placeData?.formatted_address,
        name: placeData?.name,
      }
      updateFormAddinput(_selectedInputVisible)
      bookifrom(modyfydata)
      setInputValue(placeData?.formatted_address || '');
      // inputRef?.current = _selectedInputVisible
      onPlaceSelect(placeAutocomplete?.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete, bookifrom, updateFormAddinput]);
  return (

    <div className='formgroup'>
      {/* <div className='formgroup'> */}
      <input ref={inputRef}
        value={inputValue}
        name="fromddress"
        onChange={handleInputChange}

      />
      {error?.fromddress ? <small className="invalidationtext">{error?.fromddress}</small> : null}
      {/* </div> */}

    </div>

  );
};

function Fromgooleplace({ error }) {
  const [selectedPlace, setSelectedPlace] = useState(null);
  let { bookifrom, contextData, updateFormAddinput } = useContext(BookingCContext)

  return (
    <APIProvider
      apiKey={contextData?.googlemapkey}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"

    >
      <div className="autocomplete-control">
        <PlaceAutocomplete

          updateFormAddinput={updateFormAddinput}
          bookifrom={bookifrom}
          contextData={contextData}
          error={error}
          onPlaceSelect={setSelectedPlace} />
      </div>
    </APIProvider>
  )


}
export default Fromgooleplace;