import Pagebanner from "../../component/PageBanner/Pagebanner"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Skeleton from '@mui/material/Skeleton';
import { Link, useLocation } from 'react-router-dom';
import './Service.css';
import { useQuery } from "@tanstack/react-query";
import { fetchHomeFootercms, fetchourService, fetchserviceDetailsById } from "../../feature/cms";
import { useContext, useEffect, useMemo, useState } from "react";
import { BookingCContext } from "../../store/context/bookingContext";
const Service = () => {
    let { contextData } = useContext(BookingCContext)
    let { isLoading: footerloading, data: footerdata } = useQuery({ queryKey: ['fottercms'], queryFn: fetchHomeFootercms })
    let { isLoading: serviceloading, data: serviceData } = useQuery({ queryKey: ['service'], queryFn: fetchourService })
    const [tabIndex, setTabIndex] = useState(contextData?.serviceIndexDetails);
    const location = useLocation();
    console.log(location?.state, 'locatiojstas')
    // caching the data
    useEffect(() => {
        window.scrollTo({
            top: 0,    // Scroll to the top of the page
            behavior: 'smooth'  // Smooth scroll
        });
    }, [])
    const cache = useMemo(() => new Map(), []);

    const getContentForTab = (index) => {
        console.log(index, 'indexesss..')
        if (cache.has(index)) {
            return cache.get(index); // Return cached data
        }

        if (index >= 0 && index < serviceData?.length) {
            const content = serviceData[index]['id']; // Access content by index
            cache.set(index, content); // Cache the new data
            return content; // Return the new content
        }

        return 'No content available.'; // Fallback content
    };

    let serviceId = getContentForTab(tabIndex);
    console.log(serviceId, 'serviidd')
    const { data: serviceDetails, isLoading, error } = useQuery(
        ['serviceDetails', serviceId], // Unique query key based on serviceId
        () => fetchserviceDetailsById(serviceId),
        {
            enabled: !!serviceId,
            staleTime: 5000,
            cacheTime: 10000,
        }
    );

    return (
        <div className="servicePage">
            <Pagebanner
                imagepath={serviceDetails?.image_path}
            />
            <div className="tabsec">
                <div className="container">
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={(index) => setTabIndex(index)}
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <div className="tbList">
                                    <h3>Services</h3>
                                    <TabList >

                                        {
                                            serviceloading ?
                                                <>
                                                    <Skeleton variant='rectangular' animation='wave' />
                                                    <Skeleton variant='rectangular' animation='wave' />
                                                    {/* <Skeleton variant='rectangular' animation='wave' /> */}

                                                </>

                                                :
                                                serviceData?.map((serive, index) => {
                                                    return <Tab
                                                        style={{
                                                            color: tabIndex == index ? '#CC8B16' : ''
                                                        }}
                                                        value={serive?.id}><i class="fa fa-angle-double-right" aria-hidden="true"></i> {serive?.name}</Tab>

                                                })
                                        }

                                    </TabList>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="tabpnl">

                                    {/* {
                                        serviceId == 1 || serviceId == 2 ? */}
                                    {
                                        serviceData?.map((servi) => {
                                            return <TabPanel tabIndex={tabIndex}>
                                                <p dangerouslySetInnerHTML={{ __html: serviceDetails?.description }}>

                                                </p>


                                            </TabPanel>
                                        })
                                    }


                                    {/* : null
                                    } */}
                                </div>
                            </div>
                        </div>

                    </Tabs>


                </div>

            </div>

            <div className="whyChooseUs">
                <div className="container">


                    <div className="row">
                        <div className="col-md-12">
                            <div className="callbook" style={{ backgroundImage: `url(${footerdata?.image_3_path})` }}>
                                <h2>{footerdata?.title_3}</h2>
                                <p dangerouslySetInnerHTML={{ __html: footerdata?.description_3 }}></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="chooseItem">
                                <div className="chooseItemtop">
                                    <img src={footerdata?.image_1_path} /> <h3>{footerdata?.title}</h3>
                                </div>
                                {
                                    footerloading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :

                                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description }}></p>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="chooseItem">
                                <div className="chooseItemtop">
                                    <img src={footerdata?.image_2_path} /> <h3>{footerdata?.title_2}</h3>
                                </div>
                                {
                                    footerloading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :

                                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description_2 }}></p>
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contInfo">
                                <h2>{footerdata?.title_3}</h2>
                                <div className="btnGrp">
                                    <a
                                        href={`tel:+${contextData?.mobilenumber}`}
                                        className="bookBtn btn1" to="">Call to book <span><i className="fa fa-phone" aria-hidden="true"></i></span></a>

                                    <Link className="bookBtn" to="/booking">Book Now <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Service;