import * as Yup from 'yup'
export const bookingTripschema = Yup.object({
    fromddress: Yup.string().required('Select pickup Location'),
    toadress: Yup.string().required('Select Destination Location'),
    viaaddress: Yup.string(),
    passenger: Yup.number().required('Select no of Passenger'),
    laguagge: Yup.number().required('Select no of lagguage'),
    pickupdate: Yup.string().required('Select Pickup Date'),
    addiAddress: Yup.string(),
    pickuptime: Yup.string().required('Select Time'),
    triptype: Yup.number(),

})

export const customerDetails = Yup.object({
    customer_name: Yup.string().required('Enter Name'),
    customer_email: Yup.string().required('Enter valid Email'),
    customer_phone: Yup.string().length(10).required('Enter phone Number'),
    additional_information: Yup.string(),
    terms: Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions to proceed. Please check the box to confirm your agreement before submitting the form.').required(''),
    privacy: Yup.boolean().oneOf([true], 'You must accept the Privacy Policy to proceed. Please check the box to confirm your agreement before submitting the form.').required('')
})

export const returnModalSchema = Yup.object({
    return_flight_no: Yup.string(),
    return_date: Yup.string().required('Select Return Date'),
    return_time: Yup.string().required('Please Select Return Time')
})


export const contactusschema = Yup.object({
    name: Yup.string().required('Please Enter Name'),
    email: Yup.string().required('Please Enter Email'),
    phone: Yup.string().required('Please Enter Phone'),
    message: Yup.string().required('Please Enter Message')
})

export const getquestshema = Yup.object({
    from_address: Yup.string().required('Select Pick-up Location'),
    to_address: Yup.string().required('Select Destination Location'),
    via_address: Yup.string(),
    pickup_date: Yup.string().required('Select Date'),
    pickup_time: Yup.string().required('Enter Pick-up Time'),
    luggage: Yup.number(),
    passenger: Yup.number(),
    name: Yup.string().required('Please Enter Name'),
    email: Yup.string().required('Please Enter Email'),
    phone: Yup.string().required('Please Enter Phone'),
    tip_type: Yup.number().required('Select Trip Type')
})