import { useContext, useEffect } from 'react';
import Pagebanner from '../../component/PageBanner/Pagebanner';
import './AboutUs.css';
import Skeleton from '@mui/material/Skeleton';
import about from "../../assets/aboutus.png";
import { Link } from 'react-router-dom';
import choose from "../../assets/why_choose.png";
import like from "../../assets/like.png";
import maps from "../../assets/maps.png";
import accept from "../../assets/accept.png";
import { useQuery } from '@tanstack/react-query';
import { fetchaboutUs, fetchHomeFootercms, fetchwhycchooseUs } from '../../feature/cms';
import { BookingCContext } from '../../store/context/bookingContext';
const AboutUs = () => {
    let { contextData } = useContext(BookingCContext)

    let { isLoading: loading, data, error } = useQuery({ queryKey: ['aboutus'], queryFn: fetchaboutUs })
    let { isLoading: whychooseusLoading, data: chooseusData } = useQuery({ queryKey: ['chooseus'], queryFn: fetchwhycchooseUs })
    let { isLoading: footerloading, data: footerdata } = useQuery({ queryKey: ['fottercms'], queryFn: fetchHomeFootercms })

    useEffect(() => {
        window.scrollTo({
            top: 0,    // Scroll to the top of the page
            behavior: 'smooth'  // Smooth scroll
        });
    }, [])
    return (
        <div className='aboutUspage'>
            <Pagebanner />
            <div className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="aboutPic">
                                <img src={data?.image_1_path} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="aboutTxt">
                                <h2 dangerouslySetInnerHTML={{__html:data?.title_2}}>
                                </h2>

                                {
                                    loading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whyChooseUs">
                <div className="container">
                    <div class="heading"><span>Why Choose Us</span><h3>Unmatched Quality and Service
                        for your needs</h3></div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description}</p>

                                }

                            </div>
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description_2}</p>
                                }
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="whyChooseImg">
                                <img src={chooseusData?.image_1_path} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description_3}</p>
                                }
                            </div>
                            <div className="whychooseItem">
                                <img src={accept} />
                                {
                                    whychooseusLoading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :
                                        <p>{chooseusData?.description_4}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="chooseItem">
                                <div className="chooseItemtop">
                                    <img src={footerdata?.image_1_path} /> <h3>{footerdata?.title}</h3>
                                </div>
                                {
                                    footerloading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />

                                        </>
                                        :

                                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description }}></p>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="chooseItem">
                                <div className="chooseItemtop">
                                    <img src={footerdata?.image_2_path} /> <h3>{footerdata?.title_2}</h3>
                                </div>
                                {
                                    footerloading ?
                                        <>
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                            <Skeleton animation='wave' />
                                        </>
                                        :

                                        <p dangerouslySetInnerHTML={{ __html: footerdata?.description_2 }}></p>
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contInfo">
                                <h2>{footerdata?.title_3}</h2>
                                <div className="btnGrp">
                                    <a
                                        href={`tel:+${contextData?.mobilenumber}`}
                                        className="bookBtn btn1" to="">Call to book <span><i className="fa fa-phone" aria-hidden="true"></i></span></a>

                                    <Link className="bookBtn" to="/booking">Book Now <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUs;