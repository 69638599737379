import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logo.png"
import './Header.css';
import { useContext, useState } from "react";
import { useEffect } from "react";
import { BookingCContext } from "../../store/context/bookingContext";
const Header = () => {
    let { contextData } = useContext(BookingCContext)
    const [getInnerwidth, setinnerWidth] = useState();
    const [tglBtn, settglBtn] = useState(false)
    useEffect(() => {

        setinnerWidth(window.innerWidth)
    }, [getInnerwidth])
    const [isActive, setisActive] = useState();
    const setActivefun = (id) => {
        setisActive(id);
        settglBtn(!tglBtn)
    }
    const menu = [
        {
            label: "Home",
            url: "/"
        },
        {
            label: "About Us",
            url: "/about-us"
        },
        {
            label: "Services",
            url: "/service"
        },
        {
            label: "Gallery",
            url: "/gallery"
        },
        {
            label: "Contact",
            url: "/contact-us"
        }

    ]
    return (
        <header className="header">

            <div className="container">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="Logo" />
                    </Link>


                    <button type="button" className="menuBtn" onClick={() => { settglBtn(!tglBtn) }}><i class="fa fa-bars" aria-hidden="true"></i></button>
                </div>
                <menu className={tglBtn ? "active" : ""}>
                    {menu.map((item, index) => {
                        return <li ><NavLink
                            to={item?.url}
                            className={({ isActive }) => (isActive ? "active" : "")}  // Use a function to set the class
                        >
                            {item?.label}
                        </NavLink></li>
                    })

                    }

                </menu>
                <div className="conatct">
                    <Link className="bookBtn" to="/booking">Book Now <span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></Link>
                    <a className="callBtn" href={`tel:+${contextData?.mobilenumber}`}><i className="fa fa-phone" aria-hidden="true"></i></a>
                </div>
            </div>
        </header>
    )
}
export default Header;