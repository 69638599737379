import React, { useCallback, useEffect, useMemo, useState } from "react";
import { tuple } from "yup";
export const BookingCContext = React.createContext();

function ContextWraper({ children }) {

    const [contextData, setContextData] = useState(
        {
            fromAddressData: {},
            toAddressData: {},
            viaAddress: {
                address: '',
                address_latitude: '',
                address_longitude: ''
            },
            viaaddressArray: [],
            travelMode: 'DRIVING',
            checkaddresActivity: 0,
            totalDistance: 0,
            totalhourse: 0,
            totalminute: 0,
            usercurrentSlide: 1,
            fromAddinputselected: null,
            toAddinputselected: null,
            viaAddinputselected: null,
            location_pickup_details: {
                "pickup_date": null,
                "pickup_flight_no": '',
                "trip_type": 1,
                "total_passenger": 1,
                "total_luggage": 1,
                "pickup_hour":  '00:00',
                "pickup_minute":  '00:00',
                "return_flight_no": '',
                "return_hour": '00:00',
                "return_minute": '00:00',
                "return_date": null,
                "pickup_time":null,
                "return_time":null
            },
            carDetails: {},
            timeText: '',
            customerDetails: {
                customer_name: '',
                customer_email: '',
                customer_phone: '',
                additional_information: '',
                termscon: false,
                privacy: false
            },
            mobilenumber: '',
            serviceIndexDetails: 0,
            googlemapkey: '',
            pamentBypaynow: false,
            bookingConfirm: false

        }
    )

    const bookingConCheck = () => {
        setContextData(prev => {
            return {
                ...prev,
                bookingConfirm: true
            }
        })
    }

    const paymentbyOnline = (text, value) => {
        switch (text) {
            case 'openOnline':
                setContextData(prev => {
                    return {
                        ...prev,
                        pamentBypaynow: value
                    }
                })
                break;
            case 'closeOnline':
                setContextData(prev => {
                    return {
                        ...prev,
                        pamentBypaynow: value
                    }
                })
                break;

            default:
                break;
        }

    }

    const removeViaLocation = () => {
        setContextData(prev => {
            return {
                ...prev,
                viaAddress: {
                    address: '',
                    address_latitude: '',
                    address_longitude: ''
                },
                viaAddinputselected: null,
                checkaddresActivity: 5
            }
        })
    }

    const addviaddress = () => {
        setContextData(prev => {
            return {
                ...prev,
                viaaddressArray: [...prev.viaaddressArray, {
                    address: prev?.viaAddress?.address,
                    address_latitude: prev?.viaAddress?.address_latitude,
                    address_longitude: prev?.viaAddress?.address_longitude
                }],
                checkaddresActivity: prev?.checkaddresActivity + 1

            }
        })
    }

    const removeVialoaction = (indexs) => {
        setContextData(prev => {
            return {
                ...prev,
                viaaddressArray: prev?.viaaddressArray?.filter((i, index) => index !== indexs),
                checkaddresActivity: prev?.checkaddresActivity + 1

            }
        })
    }
    const updateCarDetails = (data) => {
        setContextData(prev => {
            return {
                ...prev,
                carDetails: data
            }
        })
    }

    const update_location_details = (updateParam, value) => {
        switch (updateParam) {
            case "phonenumber":
                setContextData(prev => {
                    return {
                        ...prev,
                        mobilenumber: value
                    }
                })
                break;
            case "googlemapkey":
                console.log(value, 'kerysuiresyrsuey')
                setContextData(prev => {
                    return {
                        ...prev,
                        googlemapkey: value
                    }
                })
                break;
            case "passenser":
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            total_passenger: value
                        }
                    }
                })
                break;
            case "luggage":
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            total_luggage: value
                        }
                    }
                })
                break;
            case 'pickup_data':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            pickup_date: value
                        }
                    }
                })
                break;
            case 'pickup_hour':
                setContextData(prev => {
                    let data = value?.split(":");
                    console.log(data,'fsfsdf')
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            pickup_hour: `${data[0]}:00`,
                            pickup_minute: `${data[1]}:00`
                        }
                    }
                })
                break;
            case 'pickup_minute':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            pickup_minute: value
                        }
                    }
                })
                break;
                case 'pickup_time':
                    setContextData(prev => {
                        return {
                            ...prev,
                            location_pickup_details: {
                                ...prev.location_pickup_details,
                                pickup_time: value
                            }
                        }
                    })
                    break;
                    case 'return_time':
                        setContextData(prev => {
                            return {
                                ...prev,
                                location_pickup_details: {
                                    ...prev.location_pickup_details,
                                    return_time: value
                                }
                            }
                        })
                        break;
            case 'pickup_flight_no':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            pickup_flight_no: value
                        }
                    }
                })
                break;
            case 'trip_type':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            trip_type: value
                        }
                    }
                })
                break;
            case 'retunrdate':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            return_date: value
                        }
                    }
                })
                break;

            case 'return_hr':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            return_hour: `${value?.split(":")[0]}:00`,
                            return_minute: `${value?.split(":")[1]}:00`
                        }
                    }
                })
                break;
            case 'return_min':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            return_minute: value
                        }
                    }
                })
                break;
            case 'return_details':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            return_flight_no: value
                        }
                    }
                })
                break;

            case 'return_close':
                setContextData(prev => {
                    return {
                        ...prev,
                        location_pickup_details: {
                            ...prev.location_pickup_details,
                            trip_type: 1
                        }
                    }
                })
                break;

            case 'viewserviceDetails':
                setContextData(prev => {
                    return {
                        ...prev,
                        serviceIndexDetails: value
                    }
                })
                break;


            default:
                break;
        }
    }

    const updateCustomerDetails = (paramsText, value) => {
        switch (paramsText) {
            case 'name':
                setContextData(prev => {
                    return {
                        ...contextData,
                        customerDetails: {
                            ...prev.customerDetails,
                            customer_name: value
                        }
                    }
                })
                break;
            case 'email':
                setContextData(prev => {
                    return {
                        ...contextData,
                        customerDetails: {
                            ...prev.customerDetails,
                            customer_email: value
                        }
                    }
                })
                break;
            case 'phone':
                setContextData(prev => {
                    return {
                        ...contextData,
                        customerDetails: {
                            ...prev.customerDetails,
                            customer_phone: value
                        }
                    }
                })
                break;
            case 'addi':
                setContextData(prev => {
                    return {
                        ...contextData,
                        customerDetails: {
                            ...prev.customerDetails,
                            additional_information: value
                        }
                    }
                })
                break;
            case 'terms':
                console.log(value, 'tererslrk')
                setContextData(prev => {
                    return {
                        ...contextData,
                        customerDetails: {
                            ...prev.customerDetails,
                            termscon: value
                        }
                    }
                })
                break;
            case 'privacy':
                console.log(value, 'ffstf')
                setContextData(prev => {
                    return {
                        ...contextData,
                        customerDetails: {
                            ...prev.customerDetails,
                            privacy: value
                        }
                    }
                })
                break;
            default:
                break;
        }

    }
    const updateFormAddinput = (data) => {
        setContextData(prevvData => {
            return {
                ...prevvData,
                fromAddinputselected: data
            }
        })
    }

    const updateFormtoinput = (data) => {
        setContextData(prevvData => {
            return {
                ...prevvData,
                toAddinputselected: data
            }
        })
    }
    const updateFormviainput = (data) => {
        setContextData(prevvData => {
            return {
                ...prevvData,
                viaAddinputselected: data
            }
        })
    }
    const nextCurentslide = () => {
        setContextData(prevData => {
            return {
                ...prevData,
                usercurrentSlide: prevData?.usercurrentSlide + 1,
                checkaddresActivity: 10
            }
        })
    }

    const previousSlide = () => {
        setContextData(prevData => {
            return {
                ...prevData,
                usercurrentSlide: prevData?.usercurrentSlide - 1
            }
        })
    }
    const bookifrom = (data) => {
        setContextData(prevData => {
            return {
                ...prevData,
                fromAddressData: data,
                checkaddresActivity: prevData.checkaddresActivity + 1
            }
        })
    }

    const toaddressData = (data) => {
        setContextData(prevData => {
            return {
                ...prevData,
                toAddressData: data,
                checkaddresActivity: prevData.checkaddresActivity + 2
            }
        })
    }

    const viaddress = (data) => {
        setContextData(prevData => {
            return {
                ...prevData,
                viaAddress: data,
                // checkaddresActivity: prevData.checkaddresActivity - 1
            }
        })
    }


    const calculateDistance = useCallback(() => {
        if (!window.google || !contextData?.fromAddressData?.from_address || !contextData?.toAddressData?.to_address) {
            return;
        }

        const service = new window.google.maps.DirectionsService();
        const origin = contextData.fromAddressData.from_address;
        const destination = contextData.toAddressData.to_address;

        const waypoints = contextData.viaaddressArray?.map(via => ({
            location: via.address,
            stopover: true,
        })) || [];

        service.route(
            {
                origin,
                destination,
                waypoints,
                travelMode: 'DRIVING',
                unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (response, status) => {
                if (status === 'OK' && response.routes.length > 0) {
                    const route = response.routes[0];
                    let totalDistance = 0;
                    let totalDuration = 0;

                    route.legs.forEach(leg => {
                        totalDistance += leg.distance.value; // distance in meters
                        totalDuration += leg.duration.value; // duration in seconds
                    });

                    totalDistance = totalDistance / 1609.34; // meters to miles
                    const hours = Math.floor(totalDuration / 3600);
                    const minutes = Math.floor((totalDuration % 3600) / 60);
                    let totalMinutes = (hours * 60) + minutes;

                    // Calculate days, remaining hours, and remaining minutes
                    let days = Math.floor(totalMinutes / 1440); // 1440 minutes in a day
                    let remainingMinutesAfterDays = totalMinutes % 1440;
                    let remainingHours = Math.floor(remainingMinutesAfterDays / 60);
                    let remainingMinutes = remainingMinutesAfterDays % 60;

                    let result = [];
                    if (days > 0) result.push(`${days} days`);
                    if (remainingHours > 0) result.push(`${remainingHours} hours`);
                    if (remainingMinutes > 0) result.push(`${remainingMinutes} minutes`);

                    setContextData(prevData => ({
                        ...prevData,
                        totalDistance,
                        totalhourse: hours,
                        totalminute: minutes,
                        timeText: result.join(" ")
                    }));
                }
            }
        );
    }, [contextData.checkaddresActivity]);


    // const initMap = useCallback(() => {
    //     if (window.google) {
    //         calculateDistance();
    //     }
    // }, [calculateDistance]);


    useEffect(() => {
        if (window.google && contextData.checkaddresActivity) {
            calculateDistance();
        } else if (!window.google) {
        }
    }, [contextData.checkaddresActivity, calculateDistance]);
    // useMemo(() => {
    //     calculatedistance()
    // }, [contextData?.checkaddresActivity])
    // const loadGoogleMapsScript = () => {
    //     const script = document.createElement('script');
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=${contextData?.googlemapkey}&callback=initMap&libraries=places&v=weekly`;
    //     script.async = true;
    //     script.defer = true;
    //     document.body.appendChild(script);
    // };



    return (

        <BookingCContext.Provider value={{
            contextData, bookifrom, toaddressData,
            viaddress, nextCurentslide, previousSlide,
            updateFormAddinput,
            updateFormAddinput,
            updateFormviainput,
            updateFormtoinput,
            update_location_details,
            updateCarDetails,
            updateCustomerDetails,
            removeViaLocation,
            paymentbyOnline,
            addviaddress,
            removeVialoaction,
            bookingConCheck

        }}>
            {children}
        </BookingCContext.Provider>

    )
}

export default ContextWraper;

