
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import {
    APIProvider,
    useMapsLibrary,
} from "@vis.gl/react-google-maps"
import { BookingCContext } from "../../store/context/bookingContext";

const PlaceAutocomplete = ({ onPlaceSelect ,error,toaddressData,contextData,updateFormtoinput}) => {
    // let { toaddressData,contextData,updateFormtoinput} = useContext(BookingCContext)

    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(contextData?.toAddinputselected?.formatted_address || '');

    const places = useMapsLibrary("places");
    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Update the input field value on change
      };
    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ["geometry", "name", "formatted_address"],
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);
    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener("place_changed", () => {
            let placeData = placeAutocomplete.getPlace()
            let modyfydata = {
                to_address: placeData?.formatted_address,
                to_latitude: placeData?.geometry?.location.lat(),
                to_longitude: placeData?.geometry?.location.lng()
            }

            let _selectedInputVisible = {
                formatted_address: placeData?.formatted_address,
                name: placeData?.name,
              }
              updateFormtoinput(_selectedInputVisible)
              setInputValue(placeData?.formatted_address || '');

            toaddressData(modyfydata)
            onPlaceSelect(placeAutocomplete.getPlace());
        });
    }, [onPlaceSelect, placeAutocomplete,toaddressData,updateFormtoinput]);
    return (

        <div className= 'formgroup'>
            {/* <div className='formgroup'> */}
            <input ref={inputRef}
            name="toadress"
             value={inputValue}
             onChange={handleInputChange}
            />
               {error?.toadress ? <small className="invalidationtext">{error?.toadress}</small> : null}
            {/* </div> */}

        </div>

    );
};

function FromgooleplaceTo({error}) {
    const [selectedPlace, setSelectedPlace] = useState(null);
    let { toaddressData,contextData,updateFormtoinput} = useContext(BookingCContext)

    return (
        <APIProvider
            apiKey={contextData?.googlemapkey}
            solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"

        >
            <div className="autocomplete-control">
                <PlaceAutocomplete
                updateFormtoinput={updateFormtoinput}
                toaddressData={toaddressData}
                contextData={contextData}
                error={error}
                onPlaceSelect={setSelectedPlace} />
            </div>
        </APIProvider>
    )


}
export default FromgooleplaceTo;