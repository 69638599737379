import './Booking.css';
import account from "../../assets/account.png";
import Pagebanner from "../../component/PageBanner/Pagebanner";
import { Link } from 'react-router-dom';
import BookingCusDetails from '../../component/Booking/bookingCusDetails';
import BookingDetails from '../../component/Booking/bookingDetails';
import TripDetails from '../../component/Booking/tripDetails';
import BookingSelectDrive from '../../component/Booking/bookingSelectDrive';
import BookingPaymentDetails from '../../component/Booking/bookingPayment';
import ContextWraper from '../../store/context/bookingContext';
import BookingTrackingStage from '../../component/Booking/BookingTrackingStage';
import BookingSlides from '../../component/Booking/BookingSlide';
import { useEffect } from 'react';
const Booking = () => {
useEffect(() =>{
  window.scrollTo({
    top: 0,    // Scroll to the top of the page
    behavior: 'smooth'  // Smooth scroll
  });
},[])
  return (
    // <ContextWraper>
      <div className="bookingpage">
        <Pagebanner />
        <div className='booksec'>
          <div className='container'>
            <div className='bookprocess'>
              <BookingTrackingStage />

              <BookingSlides />
            </div>
          </div>
        </div>
      </div>

    // </ContextWraper>
  )
}
export default Booking;