
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import {
  APIProvider,
  useMapsLibrary,
} from "@vis.gl/react-google-maps"
import { BookingCContext } from "../../store/context/bookingContext";

const PlaceAutocomplete = ({ onPlaceSelect, viaddress, updateFormviainput, contextData }) => {

  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState(contextData?.viaAddinputselected?.formatted_address || '');

  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    setInputValue(contextData?.viaAddinputselected?.formatted_address || '');
  }, [contextData?.viaAddinputselected]); // Trigger re-render when context data changes

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      let placeData = placeAutocomplete.getPlace()
      let modyfydata = {
        address: placeData?.formatted_address,
        address_latitude: placeData?.geometry?.location.lat(),
        address_longitude: placeData?.geometry?.location.lng()
      }
      let _selectedInputVisible = {
        formatted_address: placeData?.formatted_address,
        name: placeData?.name,
      }
      updateFormviainput(_selectedInputVisible)
      setInputValue(placeData?.formatted_address || '');
      viaddress(modyfydata)
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete, viaddress, updateFormviainput]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update the input field value on change
  };
  return (
    <div className='formgroup'>
      <input ref={inputRef}
        name="viaaddress"
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
};

function FromgooleplaceVia() {
  const [selectedPlace, setSelectedPlace] = useState(null);
  let { viaddress, updateFormviainput, contextData } = useContext(BookingCContext)

  return (
    <APIProvider
      apiKey={contextData?.googlemapkey}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"

    >
      <div className="autocomplete-control">
        <PlaceAutocomplete
          updateFormviainput={updateFormviainput}
          viaddress={viaddress}
          contextData={contextData}
          onPlaceSelect={setSelectedPlace} />
      </div>
    </APIProvider>
  )


}
export default FromgooleplaceVia;