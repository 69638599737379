import { useContext } from 'react'
import '../../page/Booking/Booking.css'
import { BookingCContext } from '../../store/context/bookingContext'
import { useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
function TripDetails({ validateBookingForm }) {

    let { previousSlide, nextCurentslide, contextData } = useContext(BookingCContext)
    const submitBookingFormRef = useRef(null);

    const handleNextClick = () => {

        if (contextData?.usercurrentSlide == 2) {
            if (contextData?.carDetails?.id == undefined) {
                toast.error('Please Select Car')
                return
            }
            nextCurentslide()
            return
        }
        if (validateBookingForm) {

            // Call `submitForm` in BookingCusDetails through validateBookingForm
            validateBookingForm()?.then((isValid) => {
                if (isValid) {
                    nextCurentslide();
                }
            });
        }


    };
    return (
        <>
            <ToastContainer />
            <div className='bookingForm'>
                <form>
                    <h3>Trip Details</h3>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='frmItem addrescontainer'>
                                <div>


                                    <label>From</label>
                                    <p>{contextData?.fromAddressData?.from_address}</p>

                                    {
                                        contextData?.viaaddressArray?.length > 0 ?
                                            <label>Via</label>
                                            : null


                                    }
                                    {
                                        contextData?.viaaddressArray?.map((via) => {
                                            return <p>{via?.address},</p>
                                        })
                                    }

                                    <label>To</label>
                                    <p>{contextData?.toAddressData?.to_address}</p>
                                </div>
                                <div>
                                    {
                                        contextData?.location_pickup_details?.trip_type == 2 ?
                                            <>

                                                <label>Return</label>
                                                <p> Return Date: {contextData?.location_pickup_details?.return_date}</p>
                                                <p> Time: {contextData?.location_pickup_details?.return_time} </p>
                                                <p>
                                                    Flight Number, Train Station, Sea Port, Ferry:
                                                </p>
                                                <p>{contextData?.location_pickup_details?.return_flight_no}</p>
                                            </>
                                            :

                                            null
                                    }
                                </div>
                            </div>
                            <div className='frmItem'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>Date & Time</label>
                                        <p>{contextData?.location_pickup_details?.pickup_date} {contextData?.location_pickup_details?.pickup_time}
                                        </p>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Distance / Time:</label>
                                        <p>{Math.floor(contextData?.totalDistance)} km {contextData?.timeText}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='frmItem'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label>Passengers</label>
                                        <p>{contextData?.location_pickup_details?.total_passenger}</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Luggage</label>
                                        <p>{contextData?.location_pickup_details?.total_luggage}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='frmItem'>
                                <label>Flight Number, Train Station, Sea Port, Ferry Name</label>
                                <p>{contextData?.location_pickup_details?.pickup_flight_no}</p>
                            </div>
                        </div>
                    </div>
                    <div className='frmBtn'>
                        <button onClick={() => previousSlide()} type='button' className='prvBtn'>Previous </button>
                        {/* {
                            contextData?.usercurrentSlide == 2 ? */}
                        {
                            contextData?.usercurrentSlide != 4 ?


                                <button type='button'
                                    disabled={contextData?.carDetails?.id == undefined ? true : false}
                                    style={{
                                        backgroundColor: contextData?.carDetails?.id == undefined ? 'gray' : ''
                                    }}
                                    onClick={() => handleNextClick()} className='nextBtn'>Next</button>
                                :
                                null

                        }

                        {/* : contextData?.usercurrentSlide == 3 ?

                                    <button type='button'
                                        style={{
                                            backgroundColor: contextData?.customerDetails?.termscon == null || contextData?.customerDetails?.privacy == null ? 'gray' : '#00DE00'
                                        }}
                                        onClick={() => handleNextClick()} className='nextBtn'>Next</button> : null
                        } */}
                    </div>
                </form>
            </div>
        </>
    )
}

export default TripDetails
