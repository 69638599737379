import { Link } from 'react-router-dom';
import './Footer.css';
import { useQuery } from '@tanstack/react-query';
import { getsettingDetails } from '../../feature/cms';
const Footer = () => {
    let { isLoading: loading, data, error } = useQuery({ queryKey: ['setting'], queryFn: getsettingDetails })

    return (
        <div className=''>
            <footer className="footer">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <h4>Corporate Transfers</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Luton Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">London City Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Manchester Airport </Link>
                                        </li>
                                    </menu>
                                </div>
                                <div className='col-md-3'>
                                    <h4>Airport Transfers</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Luton Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">London City Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Manchester Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Edinburgh Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Glasgow Airport </Link>
                                        </li>
                                    </menu>
                                </div>
                                <div className='col-md-3'>
                                    <h4>Event Transfers</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Luton Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">London City Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Manchester Airport </Link>
                                        </li>
                                    </menu>
                                </div>
                                <div className='col-md-3'>
                                    <h4>Conference Transfer</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Luton Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">London City Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Manchester Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Edinburgh Airport </Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Glasgow Airport </Link>
                                        </li>
                                    </menu>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <h4>Quick Links</h4>
                            <menu>
                                <li>
                                    <Link to="">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/booking">Book Now</Link>
                                </li>
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact</Link>
                                </li>
                                <li>
                                    <Link to='/terms-and-conditions'>Terms of use</Link>
                                </li>
                            </menu>
                        </div>
                    </div>
                    <div className='row mgnTop'>
                        <div className='col-md-9'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <h4>Cruise Ship Transfers</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport</Link>
                                        </li>

                                    </menu>
                                </div>
                                <div className='col-md-3'>
                                    <h4>Day Tour Transfer</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport</Link>
                                        </li>
                                    </menu>
                                </div>
                                <div className='col-md-3'>
                                    <h4>Wedding Car Hire</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport</Link>
                                        </li>
                                    </menu>
                                </div>
                                {/* <div className='col-md-3'>
                                    <h4>Airport Transfers</h4>
                                    <menu>
                                        <li>
                                            <Link to="/booking">Airport transfers</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Heathrow Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Gatwick Airport</Link>
                                        </li>
                                        <li>
                                            <Link to="/booking">Stansted Airport</Link>
                                        </li>
                                    </menu>
                                </div> */}
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <h4>Contact Us</h4>
                            <menu className='contactinfo'>
                                <li>
                                    <Link to=""><i className="fa fa-phone" aria-hidden="true"></i> {data?.phone}</Link>
                                </li>
                                <li>
                                    <Link to=""><i className="fa fa-envelope" aria-hidden="true"></i>{data?.email}</Link>
                                </li>
                                <li>
                                    <Link to=""><i className="fa fa-map-marker" aria-hidden="true"></i>{data?.address}</Link>
                                </li>
                                <li>
                                    <Link to=""><i className="fa fa-clock-o" aria-hidden="true"></i>{data?.opening_hours}</Link>
                                </li>
                            </menu>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='footerbtm'>
                <div className='container'>
                    <p>Luxury Corporate Cars 2024 © All Rights Reserved Terms of use</p>
                    <p>Powered  by <Link to=""> Vigilant Software Services</Link></p>
                </div>
            </div>
        </div>

    )
}

export default Footer;