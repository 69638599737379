import Pagebanner from "../../component/PageBanner/Pagebanner";
import './ContactUs.css';
import call from "../../assets/call.png";
import location from "../../assets/location.png";
import time from "../../assets/time.png";
import mail from "../../assets/mail.png";
import contact from "../../assets/contact.png";
import map from "../../assets/map.png";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getsettingDetails, sendContactdetails } from "../../feature/cms";
import { useMutation, useQuery } from "@tanstack/react-query";
import { contactusschema } from "../../util/schema";
import { useFormik } from "formik";
const ContactUs = () => {
    const [formData, setFoemData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })
    const mutation = useMutation(sendContactdetails, {
        onSuccess: (data) => {
            toast.success(data.message)
        },
        onError: (error) => {
            console.error('Error submitting form:', error);
        }
    });
    let { isLoading: loading, data, error } = useQuery({ queryKey: ['setting'], queryFn: getsettingDetails })

    const { values, errors, touched, handleBlur, handleChange, handleSubmit
    } = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: ''
        },
        validationSchema: contactusschema,
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async () => {
            mutation.mutate(values);
        }

    })

    // function onDataSubmit() {
    //     // e.preventDefault();
    //     mutation.mutate(formData);
    // }

    useEffect(() => {
        window.scrollTo({
            top: 0,    // Scroll to the top of the page
            behavior: 'smooth'  // Smooth scroll
        });
    }, [])
    return (
        <div className="contactUspage">
            <ToastContainer autoClose={1000} />
            <Pagebanner />
            <div className="contactsec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="contactForm">
                                <form onSubmit={handleSubmit}>
                                    <h3>Contact Now</h3>
                                    <div className="formgroup">
                                        <label>Name *</label>
                                        <input type="text"
                                            name="name"
                                            id="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            required placeholder="Enter Name" />
                                        {errors.name ? <small className="invalidationtext">{errors?.name}</small> : null}
                                    </div>
                                    <div className="formgroup">
                                        <label>Email *</label>
                                        <input type="text"
                                            required

                                            id="email"
                                            name="email"
                                            placeholder="Enter Email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email ? <small className="invalidationtext">{errors?.email}</small> : null}

                                    </div>
                                    <div className="formgroup">
                                        <label>Phone Number *</label>
                                        <input type="number"
                                            required
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            placeholder="Enter Phone Number"
                                            name="phone"
                                            id="phone"
                                            value={values.phone}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 10) {
                                                    console.log('logg....g')
                                                console.log( e.target.value)
                                                    handleChange(e)
                                                }
                                            }
                                            }
                                        />
                                        {errors.phone ? <small className="invalidationtext">{errors?.phone}</small> : null}

                                    </div>
                                    <div className="formgroup">
                                        <label>Message *</label>
                                        <textarea
                                            placeholder="Enter Message"
                                            // required
                                            name="message"
                                            id="message"
                                            value={values.message}
                                            onChange={handleChange}
                                        ></textarea>
                                        {errors.message ? <small className="invalidationtext">{errors?.message}</small> : null}

                                    </div>
                                    <button type="submit"

                                        // onClick={() => onDataSubmit()}
                                        class="bookBtn">Submit <span><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contactInfo">
                                <h3>CONTACT INFO</h3>
                                <div className="contItem">
                                    <img src={call} />
                                    <div className="contItemdtls">
                                        <label>Phone</label>
                                        <a href="tel:0416 012 858">{data?.phone}</a>
                                    </div>
                                </div>
                                <div className="contItem">
                                    <img src={mail} />
                                    <div className="contItemdtls">
                                        <label>Email</label>
                                        <a href="mailto:0416 012 858">{data?.email}</a>
                                    </div>
                                </div>
                                <div className="contItem">
                                    <img src={location} />
                                    <div className="contItemdtls">
                                        <label>Address</label>
                                        <p>{data?.address}</p>
                                    </div>
                                </div>
                                <div className="contItem">
                                    <img src={time} />
                                    <div className="contItemdtls">
                                        <label>Timeing</label>
                                        <p dangerouslySetInnerHTML={{ __html: data?.opening_hours }}></p>
                                        {/* <p>Mon – Fri : 9:00 – 21:00, </p>
                                        <p>Sat – Sun : 9:00 – 17:00</p> */}
                                    </div>
                                </div>
                                <div className="btmimg">
                                    <img src={contact} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">

                            <div className="maps">
                                <div className="location-div">
                                    <img src={location} />
                                    <h4>Location</h4> </div>
                                <iframe
                                    src={data?.map}
                                    // width="600"
                                    // height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="Google Map"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs;        