import { useQuery } from '@tanstack/react-query';
import Pagebanner from '../../component/PageBanner/Pagebanner';
import './TearmCondition.css';
import Skeleton from '@mui/material/Skeleton';
import { fetchtermsandcondition } from '../../feature/cms';
import { useEffect } from 'react';
const TearmCondition = () => {
    let { isLoading, data } = useQuery({ queryKey: ['termsandcondition'], queryFn: fetchtermsandcondition })
    useEffect(() =>{
        window.scrollTo({
          top: 0,    // Scroll to the top of the page
          behavior: 'smooth'  // Smooth scroll
        });
      },[])
    return (
        <div className='tearmandcondition'>
            <Pagebanner />
            <div className='pagecontent'>
                {
                    isLoading ?
                        <>
                            <Skeleton animation='wave' variant='rectangular' />
                            <Skeleton animation='wave' variant='rectangular' />
                            <Skeleton animation='wave' variant='rectangular' />
                            <Skeleton animation='wave' variant='rectangular' />
                            <Skeleton animation='wave' variant='rectangular' />
                            <Skeleton animation='wave' variant='rectangular' />

                        </>
                        :
                        <div className='container' dangerouslySetInnerHTML={{ __html: data?.description }}></div>

                }
            </div>
        </div>
    )
}

export default TearmCondition