import axios from "axios"
import baseurl from "../util/axiosBase"

const fetchCars = async (data) =>{
    let _carsData = await axios.post(`${baseurl}/get-booking-cars`,JSON.stringify(data),{
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return _carsData?.data?.data
}

const palcebooking  = async (data) =>{
    let _res = await axios.post(`${baseurl}/booking-create`,JSON.stringify(data),{
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return _res?.data
}
const sendquatationrequest = async (data) => {
    let res_data = await axios.post(`${baseurl}/get-quote`, JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res_data?.data
}

export {fetchCars,palcebooking,sendquatationrequest
    
}