import { useContext, useState } from "react"
import BookingDetails from "./bookingDetails"
import { BookingCContext } from "../../store/context/bookingContext"
import TripDetails from "./tripDetails"
import BookingSelectDrive from "./bookingSelectDrive"
import BookingCusDetails from "./bookingCusDetails"
import BookingPaymentDetails from "./bookingPayment"

const BookingSlides = () => {
    let { contextData } = useContext(BookingCContext)
    const [validateBookingForm, setValidateBookingForm] = useState(null);

    
    return (
        <div>
            {
                contextData?.usercurrentSlide == 1 ?


                    <BookingDetails /> :
                    contextData?.usercurrentSlide == 2 ?
                        <>
                            {/* <p className="text-center">selelct trip car</p> */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <TripDetails />
                                </div>
                                <div className='col-md-6'>
                                    <BookingSelectDrive />

                                </div>
                            </div>
                        </> :
                        contextData?.usercurrentSlide == 3 ?
                            <>
                                {/* customer details */}
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <TripDetails
                                            validateBookingForm={validateBookingForm}
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <BookingCusDetails
                                            onValidate={(submitForm) => setValidateBookingForm(() => submitForm)}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            contextData?.usercurrentSlide ?

                                <>
                                    {/* payment details */}
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <TripDetails />

                                        </div>
                                        <div className='col-md-6'>
                                            <BookingPaymentDetails />
                                        </div>
                                    </div>
                                </>
                                : null

            }



        </div>
    )
}

export default BookingSlides