
import { Link } from 'react-router-dom'
import '../../page/Booking/Booking.css'
import { useQuery } from '@tanstack/react-query'
import { fetchCars } from '../../feature/booking'
import { useContext } from 'react'
import { BookingCContext } from '../../store/context/bookingContext'

function BookingSelectDrive() {
  let { contextData, updateCarDetails } = useContext(BookingCContext)

  let _payloaddata = {
    total_passenger: contextData?.location_pickup_details?.total_passenger,
    total_luggage: contextData?.location_pickup_details?.total_luggage,
    total_distance: contextData?.totalDistance
  }
  let { isLoading: whychooseusLoading, data } = useQuery(['cars'], () => fetchCars(_payloaddata))

  return (
    <>
      <div className='carScroll'>
        {
          data?.map((car) => {
            return (
              <div className='carItem'>
                <div className='carItemImg'>
                  <img src={car?.image_path} />
                </div>
                <div className='carItemdtls'>
                  <h2>{car?.name}</h2>
                  <span>{car?.model_no}</span>
                  <h5>Charges :${Math.floor(car?.total_charge)}</h5>
                  {/* <p><i class="fa fa-users" aria-hidden="true"></i> 4</p>
                  <p><i class="fa fa-briefcase" aria-hidden="true"></i>4</p> */}
                  <Link onClick={() => updateCarDetails(car)}> {(contextData?.carDetails?.id !== undefined && contextData?.carDetails?.id == car?.id
                  ) ? 'Booked' : 'Book Now'} </Link>

                </div>
              </div>
            )
          })
        }


      </div>
    </>
  )
}

export default BookingSelectDrive